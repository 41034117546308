//General
import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import { IntlProvider } from "react-intl";

//Context
import LocalesContext from "../context/LocalesContext";

//Layout
import SeoStructure from "../components/SeoStructure";
import Layout from "../components/Layout";
import HeaderScripts from "../components/HeadScripts";
import ContactForm from "../components/ContactForm";

export function Head({ data }) {
  return (
    <>
      <HeaderScripts />
      <SeoStructure
        title={data.page.title}
        description={data.page.seo?.metaDesc ? data.page.seo.metaDesc : ""}
        slug={data.page.originalSlug}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${data.page.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

const PageTemplate = ({ data, pageContext }) => {
  if (_.isEmpty(data.content)) return null;

  const isContactPage = [
    "bize-ulasin",
    "contact-ja",
    "contact",
    "contacto",
    "contato",
    "contatti",
    "kontact",
    "kontakt",
    "kontakt",
    "kontakta",
    "skontaktuj-sie-z-nami",
    "yhteyshenkilo",
  ].includes(data.originalSlug);

  // Add class based on page slug
  let pageTemplateModifier;
  switch (data.originalSlug) {
    case "contact":
      pageTemplateModifier = "page-template--contact";
      break;
    case "privacy-policy":
    case "cookie-policy":
    case "impressum":
    case "disclaimer":
      pageTemplateModifier = "page-template--legal";
      break;
    default:
      pageTemplateModifier = "page-template--default";
  }

  return (
    <>
      <div className={`page-template ${pageTemplateModifier}`}>
        <div className="page-template__container">
          <div className="page-template__content">
            <h1>{data.title}</h1>
            <div
              className="page-template__description"
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </div>
          {isContactPage && (
            <div className="page-template__form">
              <ContactForm locale={pageContext.locale} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const PagesTemplate = ({ data, pageContext }) => {
  const avaiablePageTranslations = data.avaiablePostTranslations.nodes;
  const avaiableLangs = avaiablePageTranslations.map(
    (post) => post.language.slug
  );

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.slug,
          avaiableLangs: avaiableLangs,
          pageNumber: 0,
        }}
      >
        <Layout>
          <PageTemplate
            data={data.page}
            pageContext={pageContext}
            type="page"
          />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default PagesTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!) {
    avaiablePostTranslations: allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          slug
        }
      }
    }

    page: wpPage(id: { eq: $id }) {
      title
      content
      originalSlug
      seo {
        metaDesc
      }
    }
  }
`;
