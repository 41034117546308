import React from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../../i18n-translations.json";

function handleSubmit(e) {
  e.preventDefault();
  const form = document.querySelector("form");
  const formData = new FormData(form);
  console.log(formData);

  const data = Object.fromEntries(formData);
  const mailto = `mailto:hello@trendscatchers.com
    ?subject=${data.subject}
    &body=${data.message}, ${data.name}`;
  window.location.href = mailto;
}

function ContactForm({ locale }) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="contact-form">
        <form className="form form--contact" onSubmit={handleSubmit}>
          <div className="form__title">
            <h2>
              <FormattedMessage id="form_title" />
            </h2>
            <p>
              <FormattedMessage id="form_subtitle" />
            </p>
          </div>

          <div className="form form__field">
            <label htmlFor="first-name">
              <FormattedMessage id="form_name" />
            </label>
            <input id="first-name" type="text" name="name" />
          </div>

          <div className="form form__field">
            <label htmlFor="email">
              <FormattedMessage id="form_email" />
            </label>
            <input id="email" type="text" name="email" />
          </div>

          <div className="form form__field">
            <label htmlFor="subject">
              <FormattedMessage id="form_subject" />
            </label>
            <input id="subject" type="text" name="subject" />
          </div>

          <div className="form form__field">
            <label htmlFor="message">
              <FormattedMessage id="form_message" />
            </label>
            <textarea id="message" type="textarea" name="message" />
          </div>

          <div className="form form__button">
            <button
              className="button button--large"
              type="submit"
              value="Submit"
            >
              <FormattedMessage id="form_button" />
            </button>
          </div>
        </form>
      </div>
    </IntlProvider>
  );
}

export default ContactForm;
